import React, {useState} from 'react';
import {Button, Checkbox, Input, message, Modal, Select} from 'antd';
import 'firebase/firestore';
import {addDoc, collection, getDocs, orderBy, query} from 'firebase/firestore';
import {db} from '../config/firebaseConfig';
import * as XLSX from 'xlsx';

const {Option} = Select;

const WhitelistModal = ({isInviteFormVisible, setIsInviteFormVisible}) => {
    const [email, setEmail] = useState('');
    const [source, setSource] = useState('');
    const [isKol, setIsKol] = useState(false);
    const [platform, setPlatform] = useState('');
    const [platformNickname, setPlatformNickname] = useState('');
    // exportToExcel();

    const handleInviteFormSubmit = async () => {
        if (!email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
            message.error('Please fill in the correct e-mail, so that we can notify you!');
            return;
        }
        if (isKol && (!platform || !platformNickname)) {
            message.error('Please fill in all required fields');
            return;
        }

        const inviteData = {
            email: email,
            isKol: isKol,
            platform: isKol ? platform : null,
            platformNickname: isKol ? platformNickname : null,
            timestamp: new Date(),
        };

        try {
            await addDoc(collection(db, 'whitelist'), inviteData);
            const querySnapshot = await getDocs(collection(db, 'whitelist'));
            const inviteCount = 3000 + parseInt((querySnapshot.size * 10 + Math.random() * 10).toString());
            setIsInviteFormVisible(false);
            Modal.success(
                {
                    title: 'Congratulations！You’re In! 🎉',
                    content: `Current position: #${inviteCount}. You’re getting closer to unlocking RPS.live. Stay tuned.`,
                    okText: 'OK',
                    okButtonProps: {
                        style: {
                            backgroundColor: '#ec4899',
                            borderColor: '#ec4899',
                        },
                    },
                    onOk() {
                    },
                }
            );

        } catch (error) {
            message.error('Failed to submit application. Please try again.');
        }
    };


    return (
        <Modal
            title="Apply for Whitelist"
            visible={isInviteFormVisible}
            onCancel={() => setIsInviteFormVisible(false)}
            maskClosable={false}
            footer={[
                <Button key="cancel" onClick={() => setIsInviteFormVisible(false)}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={handleInviteFormSubmit}>
                    Submit
                </Button>,
            ]}
        >
            <Input
                className="mb-4"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
            />
            <Input
                className="mb-4"
                placeholder="Where did you hear about our game?"
                value={source}
                onChange={(e) => setSource(e.target.value)}
                required
            />
            <Checkbox
                checked={isKol}
                onChange={(e) => setIsKol(e.target.checked)}
            >
                Are you a social media KOL?
            </Checkbox>
            {isKol && (
                <>
                    <Input
                        className="mt-4"
                        placeholder="Enter your platform (e.g., YouTube, Instagram)"
                        value={platform}
                        onChange={(e) => setPlatform(e.target.value)}
                    />
                    <Input
                        className="mt-4"
                        placeholder="Enter your platform handle (e.g., @username)"
                        value={platformNickname}
                        onChange={(e) => setPlatformNickname(e.target.value)}
                    />
                </>
            )}
        </Modal>
    );
};

const exportToExcel = async () => {
    try {
        // 获取所有 whitelist 数据
        const querySnapshot = await getDocs(query(collection(db, 'whitelist'),
            orderBy('timestamp', 'desc')));
        const whitelistData: any = [];
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            whitelistData.push({
                email: data.email,
                isKol: data.isKol ? 'Yes' : 'No',
                platform: data.platform || '-',
                platformNickname: data.platformNickname || '-',
                timestamp: data.timestamp.toDate().toLocaleString(),
            });
        });

        // 创建工作簿
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(whitelistData);

        // 设置列宽
        const colWidths = [
            {wch: 30}, // Email
            {wch: 10}, // IsKol
            {wch: 15}, // Platform
            {wch: 20}, // Platform Nickname
            {wch: 20}, // Timestamp
        ];
        ws['!cols'] = colWidths;

        // 添加工作表到工作簿
        XLSX.utils.book_append_sheet(wb, ws, "Whitelist");

        // 生成文件名
        const fileName = `whitelist_${new Date().toISOString().split('T')[0]}.xlsx`;

        // 保存文件
        XLSX.writeFile(wb, fileName);

        message.success('Export successful!');
    } catch (error) {
        console.error('Export error:', error);
        message.error('Failed to export data. Please try again.');
    }
};

export default WhitelistModal;
