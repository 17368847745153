import React, {useState} from 'react';
import {Modal, Spin} from 'antd';
import {AnimatePresence, motion} from 'framer-motion';
import {Utils} from "../utils/utils";

const GiftModal = ({
                       isModalOpen,
                       setIsModalOpen,
                       buyItemByRdb
                   }) => {
    const [currentImage, setCurrentImage] = useState(1);
    const [loading, setLoading] = useState(false);

    const handleClick = async () => {
        if (currentImage === 1) {
            setLoading(true);
            try {
                for (const item of Object.keys(Utils.allItems)) {
                    await buyItemByRdb(item, 10);
                    await new Promise(resolve => setTimeout(resolve, 1000));
                }
                setCurrentImage(2);
            } finally {
                setLoading(false);
            }
        } else {
            setIsModalOpen(false);
        }
    };

    return (
        <>
            <Modal
                visible={isModalOpen}
                footer={null}
                style={{
                    background: 'transparent'
                }}
                modalRender={(modal) => (
                    <div className="bg-transparent">
                        {modal}
                    </div>
                )}
                closable={false}
                centered
                width='66%'
                className="[&_.ant-modal-content]:!bg-transparent [&_.ant-modal-content]:!shadow-none [&_.ant-modal-body]:!bg-transparent [&_.ant-modal-body]:!p-0"
            >
                <Spin spinning={loading}>
                    <AnimatePresence mode="wait">
                        <motion.div
                            key={currentImage}
                            initial={{opacity: 0, scale: 0.8}}
                            animate={{opacity: 1, scale: 1}}
                            exit={{opacity: 0, scale: 0.8}}
                            transition={{duration: 0.5}}
                            onClick={handleClick}
                            className="cursor-pointer bg-transparent"
                        >
                            {currentImage === 1 ? (
                                <img
                                    src="https://firebasestorage.googleapis.com/v0/b/crypto-bros-10ea6.firebasestorage.app/o/gift.png?alt=media"
                                    alt="Gift"
                                    className="w-full bg-transparent"
                                />
                            ) : (
                                <img
                                    src="https://firebasestorage.googleapis.com/v0/b/crypto-bros-10ea6.firebasestorage.app/o/gift-open.png?alt=media"
                                    alt="Multiplier"
                                    className="w-full bg-transparent"
                                />
                            )}
                        </motion.div>
                    </AnimatePresence>
                </Spin>
            </Modal>
        </>
    );
};

export default GiftModal;
