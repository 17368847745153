import React, {useCallback, useEffect, useRef, useState} from 'react';
import useSound from 'use-sound';
import {Utils} from '../utils/utils';
import {usePrivy} from '@privy-io/react-auth';
import GameRules from '../components/GameRules';
import Countdown from '../components/CountDown';
import GameResult from '../components/GameResult';
import {AnimatedItem, GameItem, Inventory} from '../context/typeDefinitions';
import {message, Spin} from 'antd';
import Alert from 'antd/es/alert/Alert';

const VIRTUAL_WIDTH = 1000;
const VIRTUAL_HEIGHT = 800;
const VIRTUAL_OBJECT_SIZE = 50; // Adjust as needed

const GameCanvas = ({
                        gameState,
                        animatedItems,
                        isSimulating,
                        setAnimatedItems,
                        onSimulationEnd,
                        startTime,
                        onSetItem,
                        itemPrices,
                        isMuted,
                        timeLeft,
                        setTimeLeft,
                        simulateGameByRdb,
                        lobbyState,
                    }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const animationRef = useRef<number | null>(null);
    const [winningType, setWinningType] = useState<string | null>(null);
    const [showPopup, setShowPopup] = useState(false);
    const [popupPosition, setPopupPosition] = useState({x: 0, y: 0});
    const [selectedPoint, setSelectedPoint] = useState<{ x: number; y: number } | null>(null);
    // Frame rate and speed settings
    const [speedMultiplier] = useState(4);
    const [frameRate] = useState(32);
    const {user} = usePrivy();
    const [showRulesPopup, setShowRulesPopup] = useState(false);

    // Sound hooks
    const [playPaperRockSound] = useSound('/assets/paper_rock.mp3', {volume: isMuted ? 0 : 0.05});
    const [playRockScissorsSound] = useSound('/assets/rock_scissors.mp3', {volume: isMuted ? 0 : 0.05});
    const [playScissorsPaperSound] = useSound('/assets/scissors_paper.mp3', {volume: isMuted ? 0 : 0.05});
    const [playWinSound] = useSound('/assets/win.mp3', {volume: isMuted ? 0 : 0.1});
    const [playLoseSound] = useSound('/assets/lose.mp3', {volume: isMuted ? 0 : 0.1});

    const [userRewards, setUserRewards] = useState<Inventory | null>(null);
    const [totalPnl, setTotalPnl] = useState<number | null>(null);
    const [startLoading, setStartLoading] = useState(false);
    const [showTips, setShowTips] = useState(false);

    // Function to simulate game with loading indicator
    const simulateGameByRdbWithLoading = (rewardsDistributedAt) => {
        if (new Date().getTime() - rewardsDistributedAt <= 15000) {
            message.warning(`Please wait for all players to be ready, there are still 
                ${(10 - (new Date().getTime() - rewardsDistributedAt) / 1500).toFixed(2)} s`);
            return;
        }
        setStartLoading(true);
        setShowTips(true);
        simulateGameByRdb();
        setTimeout(() => {
            setStartLoading(false);
            setShowTips(false);
        }, 5000);
    };

    // State to store canvas dimensions
    const [canvasDimensions, setCanvasDimensions] = useState({width: 0, height: 0});

    // Update canvas dimensions based on container size
    useEffect(() => {
        const updateCanvasDimensions = () => {
            if (containerRef.current) {
                const width = containerRef.current.clientWidth;
                const aspectRatio = VIRTUAL_HEIGHT / VIRTUAL_WIDTH;
                const height = width * aspectRatio;
                setCanvasDimensions({width, height});
            }
        };

        updateCanvasDimensions();
        window.addEventListener('resize', updateCanvasDimensions);

        return () => {
            window.removeEventListener('resize', updateCanvasDimensions);
        };
    }, []);

    // Calculate object size based on canvas dimensions
    const objectSize = (VIRTUAL_OBJECT_SIZE / VIRTUAL_WIDTH) * canvasDimensions.width;

    // Function to determine the result of an interaction between two items
    const transformItem = (item1: string, item2: string): string => {
        if ((item1 === 'Rock' && item2 === 'Scissors') || (item2 === 'Rock' && item1 === 'Scissors')) return 'Rock';
        if ((item1 === 'Scissors' && item2 === 'Paper') || (item2 === 'Scissors' && item1 === 'Paper')) return 'Scissors';
        if ((item1 === 'Paper' && item2 === 'Rock') || (item2 === 'Paper' && item1 === 'Rock')) return 'Paper';
        return item1;
    };

    // Check for collisions and update item types accordingly
    const checkCollisions = useCallback(
        (items: AnimatedItem[]): AnimatedItem[] => {
            // Ensure order for determinism by sorting by all properties
            const newItems = [...items].sort((a, b) => {
                // Sort by position first
                if (Math.abs(a.x - b.x) > 0.0001) return a.x - b.x;
                if (Math.abs(a.y - b.y) > 0.0001) return a.y - b.y;
                // Then by velocity
                if (Math.abs(a.vx - b.vx) > 0.0001) return a.vx - b.vx;
                if (Math.abs(a.vy - b.vy) > 0.0001) return a.vy - b.vy;
                // Finally by type
                return a.type.localeCompare(b.type);
            });

            for (let i = 0; i < newItems.length; i++) {
                for (let j = i + 1; j < newItems.length; j++) {
                    // Use precise math operations
                    const dx = Number((newItems[i].x - newItems[j].x).toFixed(4));
                    const dy = Number((newItems[i].y - newItems[j].y).toFixed(4));
                    const distance = Number(Math.sqrt(dx * dx + dy * dy).toFixed(4));

                    if (distance < VIRTUAL_OBJECT_SIZE && newItems[i].type !== newItems[j].type) {
                      const winner = transformItem(newItems[i].type, newItems[j].type);
                      newItems[i].type = winner;
                      newItems[j].type = winner;
                      // Play the corresponding sound
                      if (!isMuted) {
                        if (winner === 'Rock') {
                          playRockScissorsSound();
                        } else if (winner === 'Scissors') {
                          playScissorsPaperSound();
                        } else if (winner === 'Paper') {
                          playPaperRockSound();
                        }          
                    }
                  }
                }
            }
            return newItems;
        },
        [playPaperRockSound, playRockScissorsSound, playScissorsPaperSound, isMuted]
    );

    // Update positions of animated items
    const updatePositions = useCallback(() => {
        setAnimatedItems((prevItems) => {
            let updatedItems = prevItems.map((item) => {
                let newX = item.x + item.vx * speedMultiplier;
                let newY = item.y + item.vy * speedMultiplier;
                let newVx = item.vx;
                let newVy = item.vy;

                if (newX <= VIRTUAL_OBJECT_SIZE / 2 || newX >= VIRTUAL_WIDTH - VIRTUAL_OBJECT_SIZE / 2) {
                    newVx = -newVx;
                    newX = Math.max(VIRTUAL_OBJECT_SIZE / 2, Math.min(VIRTUAL_WIDTH - VIRTUAL_OBJECT_SIZE / 2, newX));
                }
                if (newY <= VIRTUAL_OBJECT_SIZE / 2 || newY >= VIRTUAL_HEIGHT - VIRTUAL_OBJECT_SIZE / 2) {
                    newVy = -newVy;
                    newY = Math.max(VIRTUAL_OBJECT_SIZE / 2, Math.min(VIRTUAL_HEIGHT - VIRTUAL_OBJECT_SIZE / 2, newY));
                }

                return {...item, x: newX, y: newY, vx: newVx, vy: newVy};
            });
            return checkCollisions(updatedItems);
        });
    }, [speedMultiplier, setAnimatedItems, checkCollisions]);

    // Start the animation loop when simulating
    useEffect(() => {
        if (isSimulating) {
            const interval = setInterval(updatePositions, frameRate);
            return () => clearInterval(interval);
        }
    }, [isSimulating, updatePositions]);

    // Check for game end conditions
    useEffect(() => {
        if (isSimulating) {
            const types = new Set(animatedItems.map((item) => item.type));
            if (types.size === 1) {
                // Stop the simulation when all items are of the same type
                const winner = Array.from(types)[0] as GameItem;
                onSimulationEnd(winner);
                setWinningType(winner);
            } else if (animatedItems.length === 0) {
                // Handle the case when there are no items left
                setWinningType(null);
                onSimulationEnd('Empty');
            }
            // If there are still multiple types, continue simulating
        }
    }, [animatedItems, isSimulating, onSimulationEnd]);

    // Update the countdown timer
    useEffect(() => {
        if (startTime) {
            setWinningType(null);
            const updateTimeLeft = () => {
                const now = Date.now();
                const remaining = Math.max(0, Math.floor((startTime - now) / 1000));
                setTimeLeft(remaining);
                if (remaining > 0) {
                    requestAnimationFrame(updateTimeLeft);
                }
            };

            updateTimeLeft();
        }
    }, [startTime]);

    // Drawing function for the canvas
    useEffect(() => {
        if (!canvasRef.current) return;

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        const draw = () => {
            if (!ctx) return;

            // Clear the canvas
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            if (!isSimulating && selectedPoint) {
                const canvasX = (selectedPoint.x / VIRTUAL_WIDTH) * canvas.width;
                const canvasY = (selectedPoint.y / VIRTUAL_HEIGHT) * canvas.height;
                ctx.fillStyle = 'rgb(255, 0, 0)';
                ctx.beginPath();
                ctx.arc(canvasX, canvasY, 5, 0, 2 * Math.PI);
                ctx.fill();
            }

            // Reset globalAlpha to 1 before drawing items
            ctx.globalAlpha = 1;

            animatedItems.forEach((item) => {
                const canvasX = (item.x / VIRTUAL_WIDTH) * canvas.width;
                const canvasY = (item.y / VIRTUAL_HEIGHT) * canvas.height;
                const size = (VIRTUAL_OBJECT_SIZE / VIRTUAL_WIDTH) * canvas.width;

                // Icon
                ctx.font = `${size}px Arial`;
                if (lobbyState.mysteryBoxMode && !isSimulating) {
                    ctx.fillText('📦', canvasX - size / 2, canvasY + size / 4);
                } else {
                    ctx.fillText(Utils.getSymbol(item.type, lobbyState.symbols), canvasX - size / 2, canvasY + size / 4);
                }
                // Only show name if the game is not simulating
                if (!isSimulating) {
                    ctx.fillStyle = item.name === 'system' ? 'blue' : 'gray';
                    ctx.font = `${size - 25}px Arial`;
                    ctx.fillText(item.name, canvasX - size / 2, canvasY + size / 4 - 38);
                }
            });

            animationRef.current = requestAnimationFrame(draw);
        };

        draw();

        return () => {
            if (animationRef.current !== null) {
                cancelAnimationFrame(animationRef.current);
            }
        };
    }, [animatedItems, isSimulating, selectedPoint, canvasDimensions, lobbyState.mysteryBoxMode, lobbyState.symbols]);

    // Handle canvas click for item placement
    const handleCanvasClick = (event: React.MouseEvent<HTMLCanvasElement>) => {
        if (isSimulating) return;
        if (!lobbyState.itemPlacementToggle) return;

        const canvas = canvasRef.current;
        if (!canvas) return;

        const rect = canvas.getBoundingClientRect();
        const x = ((event.clientX - rect.left) / canvasDimensions.width) * VIRTUAL_WIDTH;
        const y = ((event.clientY - rect.top) / canvasDimensions.height) * VIRTUAL_HEIGHT;

        setPopupPosition({x, y});
        setShowPopup(true);
        setSelectedPoint({x, y});
    };

    // Handle setting an item on the canvas
    const handleSetItem = (item: string) => {
        onSetItem(item, popupPosition.x, popupPosition.y);
        setShowPopup(false);
        setSelectedPoint(null);
    };

    // Close popup when clicking outside
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                setShowPopup(false);
                setSelectedPoint(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Calculate rewards and total PNL
    useEffect(() => {
        if (winningType && gameState.rewards && user && itemPrices) {
            const rewards = gameState.rewards[user.id] as Inventory;
            if (rewards) {
                setUserRewards(rewards);
                const pnl = Object.entries(rewards).reduce((total, [item, pnl]) => {
                    return total + pnl * itemPrices[item].price;
                }, 0);
                setTotalPnl(pnl);

                // Play sound based on total PNL
                if (pnl > 0) {
                    playWinSound();
                } else {
                    playLoseSound();
                }
            }
        }
    }, [winningType, gameState.rewards, user, itemPrices, playWinSound, playLoseSound]);

    return (
        <div className="relative" ref={containerRef}>
            <div
                className="w-full bg-gradient-to-r from-red-500 via-black to-blue-900 text-white p-3
                    rounded-t-xl font-extrabold text-center"
            >
                <div className="flex flex-col sm:flex-row justify-between items-center">
                    <div className="mb-2 sm:mb-0 text-base sm:text-xl">
                        Rewards pool: <span className="text-green-500">${gameState.pot.toFixed(2)}</span>
                    </div>
                    <div className="text-sm sm:text-base">
                        <Countdown targetGameStartTime={startTime} timeLeft={timeLeft} setTimeLeft={setTimeLeft}/>
                    </div>
                </div>
            </div>
            <div
                className="relative"
                style={{
                    width: '100%',
                    height: canvasDimensions.height,
                    overflow: 'hidden',
                    //   border: '2px solid',
                    borderColor: 'neon-yellow',
                    backgroundColor: 'white',
                }}
            >
                <canvas
                    ref={canvasRef}
                    width={canvasDimensions.width}
                    height={canvasDimensions.height}
                    className="absolute top-0 left-0 mx-auto"
                    onClick={handleCanvasClick}
                    style={{
                        zIndex: 2,
                    }}
                />
                {winningType && (
                    <GameResult
                        winningType={winningType}
                        userRewards={userRewards}
                        totalPnl={totalPnl}
                        setShowRulesPopup={setShowRulesPopup}
                        lobbyState={lobbyState}
                    />
                )}
                {showTips && <div className="w-full flex justify-center my-4">
                    <Alert
                        message="Click on where you want to place rock, paper, or scissor"
                        type="info"
                        className="rounded-xl bg-[#000000B2] text-white text-sm py-2 px-2 shadow-lg border-none text-center max-w-[800px]"
                        showIcon={false}
                    />
                </div>}
                {['active'].includes(gameState.status) || winningType ? (
                    <div
                        className="absolute inset-0 bg-gray-800 bg-opacity-10 flex justify-center items-center"
                        style={{zIndex: 3, backdropFilter: 'blur(3px)'}}
                    >
                        <button
                            className="bg-gradient-to-r from-green-500 to-purple-600 text-white font-bold py-3 px-6 rounded-full transition duration-300 ease-in-out
                                    transform hover:scale-110 hover:shadow-lg focus:outline-none focus:ring-4 focus:ring-green-300 focus:ring-opacity-50 animate-pulse"
                            onClick={() => simulateGameByRdbWithLoading(gameState.rewardsDistributedAt)}
                        >
                            <span className="mr-2 text-xl">{startLoading ? <Spin size="small"/> : '🎮'}</span>
                            <span className="relative">
                <span
                    className="absolute -inset-0.5 bg-gradient-to-r from-pink-500 to-yellow-500 rounded-full blur opacity-50 group-hover:opacity-100 transition duration-1000 group-hover:duration-200 animate-tilt"
                ></span>
                <span className="relative">{winningType ? 'Restart' : 'Start'} Game</span>
              </span>
                        </button>
                    </div>
                ) : null}
            </div>
            {showRulesPopup && <GameRules onClose={() => setShowRulesPopup(false)}/>}

            {showPopup && (
                <div
                    className="absolute bg-black bg-opacity-90 border-2 border-neon-green rounded-full p-2 transform -translate-x-1/2 -translate-y-1/2"
                    style={{
                        left: (popupPosition.x / VIRTUAL_WIDTH) * canvasDimensions.width,
                        top: (popupPosition.y / VIRTUAL_HEIGHT) * canvasDimensions.height,
                        zIndex: 4,
                    }}
                >
                    <button
                        className="m-1 p-2 bg-neon-blue text-white rounded-full hover:bg-neon-purple transition-all duration-300 transform hover:scale-110 focus:outline-none focus:ring-2 focus:ring-neon-blue"
                        onClick={() => handleSetItem('Rock')}
                    >
                        🪨
                    </button>
                    <button
                        className="m-1 p-2 bg-neon-green text-white rounded-full hover:bg-neon-yellow transition-all duration-300 transform hover:scale-110 focus:outline-none focus:ring-2 focus:ring-neon-green"
                        onClick={() => handleSetItem('Paper')}
                    >
                        📄
                    </button>
                    <button
                        className="m-1 p-2 bg-neon-red text-white rounded-full hover:bg-neon-orange transition-all duration-300 transform hover:scale-110 focus:outline-none focus:ring-2 focus:ring-neon-red"
                        onClick={() => handleSetItem('Scissors')}
                    >
                        ✂️
                    </button>
                </div>
            )}
        </div>
    );
};

export default GameCanvas;
