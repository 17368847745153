// BettingPanel.jsx
import React, {useState} from 'react';
import {Loader} from "lucide-react";
import Counter from "./InputNumber";
import {Utils} from "../utils/utils";

const BettingPanel = ({
                          playerData,
                          roomId,
                          items,
                          isSimulating,
                          currentGameId,
                          isDeploying,
                          lobbyState,
                          isTrade,
                          deployRef,
                          placeOnWallByRdb,
                          onBuy,
                          onSell,
                      }) => {
    const [itemsAddCounts, setItemsAddCounts] = useState({});
    const [itemsReduceCounts, setItemsReduceCounts] = useState({});

    const handleItemsAddCountChange = (item, value) => {
        setItemsAddCounts(prevCounts => ({
            ...prevCounts,
            [item]: value,
        }));
    };

    const handleItemsReduceCountChange = (item, value) => {
        setItemsReduceCounts(prevCounts => ({
            ...prevCounts,
            [item]: value,
        }));
    };

    return (
        <div className="bg-[#1F2937] p-4 mt-2 rounded-lg flex flex-col md:flex-col items-center">
            <div className="w-[calc(100%+2rem)] bg-[#2E3949] -mx-4 -mt-4 mb-6 rounded-lg">
                <div className="flex-1 mb-4 md:mb-0 hidden md:block">
                    <div
                        className="text-cyan-300 text-center text-base md:text-xl pt-1 mb-1 font-bold hidden md:block">
                        PLACE YOUR BETS
                    </div>
                    <div className="text-gray-400 text-center text-sm pb-1 font-bold hidden md:block">
                        Auto layout with the buttons or click the canvas to deploy
                    </div>
                </div>
            </div>

            <div className="flex-1 w-full">
                <div className="flex justify-around flex-wrap" ref={deployRef}>
                    {playerData && playerData.inventories && playerData.inventories[roomId] ? (
                        items.map((item, index) => (
                            <div
                                key={item}
                                className={`flex flex-col items-center mb-4 md:mb-0 w-1/3 ${
                                    index < items.length - 1 ? 'border-r border-gray-300' : ''
                                }`}
                            >
                                <button
                                    onClick={() => placeOnWallByRdb(item)}
                                    disabled={
                                        playerData?.inventories[roomId][item] === 0 ||
                                        isSimulating ||
                                        !currentGameId ||
                                        isDeploying
                                    }
                                    className="relative bg-blue-600 px-3 py-2 rounded-lg text-white font-bold disabled:opacity-50
                                        flex flex-col items-center overflow-hidden"
                                    style={{
                                        boxShadow: '0 0 15px 5px rgba(59, 130, 246, 0.5)',
                                        transition: 'all 0.3s ease',
                                        border: '4px solid #00FFFF',
                                        borderRadius: '16px',
                                    }}
                                >
                                    <div className="absolute inset-0 bg-blue-400 opacity-30 animate-pulse"></div>
                                    <span className="text-2xl md:text-5xl relative z-10">
                                        {Utils.getSymbol(item, lobbyState.symbols)}
                                    </span>
                                    <div
                                        className="absolute inset-0 bg-gradient-to-br from-blue-300 to-blue-600 opacity-50"></div>
                                </button>

                                <div className="mt-2 text-center">
                                    <span
                                        className="bg-indigo-200 bg-opacity-20 p-1 md:p-2 pb-1 pt-2 rounded-full shadow-lg">
                                        <span
                                            className="text-xs md:text-sm font-bold text-cyan-300"
                                            style={{textShadow: '0 0 8px rgba(80, 239, 255, 0.8)'}}
                                        >
                                            {(playerData?.inventories[roomId][item] ?? 0).toFixed(2)}
                                        </span>
                                    </span>
                                    <span className="text-xs md:text-sm text-blue-100"> left</span>
                                </div>

                                <div className="flex flex-col items-center rounded-lg p-1 mt-4 w-full md:w-auto">
                                    <div
                                        className="flex flex-col md:flex-row flex-wrap items-center justify-between px-2 md:px-4 gap-2">
                                        <Counter
                                            min={1}
                                            max={100}
                                            onChange={(value) => handleItemsAddCountChange(item, value)}
                                        />
                                        <button
                                            disabled={isTrade}
                                            onClick={() => onBuy(item, itemsAddCounts[item] || 1)}
                                            className="bg-green-600 hover:bg-green-700 text-white px-2 py-1 md:px-4 md:py-1 rounded-full
                                                text-sm font-medium mt-2 md:mt-0 mx-auto block"
                                            style={{transition: 'background-color 0.2s'}}
                                        >
                                            Buy
                                        </button>
                                    </div>

                                    <div
                                        className="flex flex-col md:flex-row flex-wrap items-center justify-between px-2 md:px-4 w-full mt-2">
                                        <Counter
                                            min={1}
                                            max={100}
                                            onChange={(value) => handleItemsReduceCountChange(item, value)}
                                        />
                                        <button
                                            disabled={isTrade}
                                            onClick={() => onSell(item, itemsReduceCounts[item] || 1)}
                                            className="bg-red-600 hover:bg-red-700 text-white px-2 py-1 md:px-4 md:py-1 rounded-full
                                                text-sm font-medium mx-auto block mt-2 md:mt-0"
                                            style={{transition: 'background-color 0.2s'}}
                                        >
                                            Sell
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <Loader/>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BettingPanel;
