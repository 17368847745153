import React, {useEffect, useState} from 'react';
import GameCanvas from './GameCanvas';
import {Utils} from '../utils/utils';
import {usePrivy} from '@privy-io/react-auth';
import {Divider} from 'antd';
import {PlayerEntry} from '../context/typeDefinitions';

const GameInterface = ({
                           gameState,
                           animatedItems,
                           isSimulating,
                           setAnimatedItems,
                           distributeRewards,
                           targetGameStartTime,
                           placeOnWallByRdb,
                           lobbyState,
                           playerNames,
                           simulateGameByRdb,
                           itemPrices,
                           canvasRef,
                       }) => {
    const {user} = usePrivy();
    const [timeLeft, setTimeLeft] = useState(0);
    const [isMuted, setIsMuted] = useState(false);

    return (
        <div className="w-full flex flex-col items-center px-2 md:px-0">
            {/* Container for the canvas and other components */}
            <div className="w-full max-w-screen-md">
                {/* Game Header */}
                <div ref={canvasRef} className="w-full">
                    <GameCanvas
                        gameState={gameState}
                        animatedItems={animatedItems}
                        isSimulating={isSimulating}
                        setAnimatedItems={setAnimatedItems}
                        onSimulationEnd={distributeRewards}
                        startTime={targetGameStartTime}
                        onSetItem={placeOnWallByRdb}
                        itemPrices={itemPrices}
                        isMuted={isMuted}
                        timeLeft={timeLeft}
                        setTimeLeft={setTimeLeft}
                        simulateGameByRdb={simulateGameByRdb}
                        lobbyState={lobbyState}
                    />
                </div>

                {/* Double Rewards Notification */}
                {lobbyState.mapMetadata.type !== 'Empty' && (
                    <div
                        className="text-sm sm:text-base md:text-xl font-bold text-white bg-[#E09C9C] p-2 mb-0 w-full flex flex-wrap items-center justify-center">
                        <span className="mr-1 sm:mr-2 text-white">📣📣📣:</span>
                        <span className="text-white">All {Utils.getSymbol(lobbyState.mapMetadata.type)}</span>
                        <span className="inline-block mx-1 sm:mx-2 font-extrabold underline text-white">DOUBLE</span>
                        <span className="text-white">for next</span>
                        <span className="ml-1 text-white">{lobbyState.mapMetadata.rounds} rounds</span>
                    </div>
                )}

                {/* Game Stats */}
                <div className="bg-black text-white p-2 mt-2 rounded-lg flex flex-row items-center justify-between">
                    <div className="flex items-center">
                        <button
                            onClick={() => setIsMuted(!isMuted)}
                            className="bg-white bg-opacity-20 hover:bg-opacity-30 rounded-full p-1 transition-all duration-300 mr-1"
                        >
                            {isMuted ? '🔇' : '🔊'}
                        </button>
                        <h2 className="text-sm font-bold">Stats</h2>
                    </div>
                    <div className="flex justify-center">
                        {['Rock', 'Paper', 'Scissors'].map(item => {
                            // @ts-ignore
                            const totalItems = Object.values(gameState.playerEntries || {}).reduce((sum, entry) => sum + (entry[item] || 0), 0);
                            const totalAllItems = Object.values(gameState.playerEntries || {}).reduce((sum, entry) =>
                                // @ts-ignore
                                sum + (entry['Rock'] || 0) + (entry['Paper'] || 0) + (entry['Scissors'] || 0), 0);
                            // @ts-ignore
                            const percentage = totalAllItems > 0 ? (totalItems / totalAllItems * 100).toFixed(1) : '0.0';
                            return (
                                <div key={item} className="flex items-center mr-2">
                                    <div className="text-lg mr-1">{Utils.getSymbol(item)}</div>
                                    <div className="w-12 bg-gray-700 rounded-full h-2 mr-1"
                                         style={{overflow: 'hidden'}}>
                                        <div className="bg-green-500 h-2 rounded-full"
                                             style={{width: `${percentage}%`}}></div>
                                    </div>
                                    <div className="text-xs">{percentage}%</div>
                                </div>
                            );
                        })}
                    </div>
                </div>

                {/* Player Stats */}
                <div className="bg-gray-700 rounded-lg p-4 mt-2 shadow-lg">
                    <h3 className="text-lg md:text-2xl font-bold text-white">Player Stats</h3>
                    <div className="text-sm md:text-base text-gray-300 mb-2">
                        {Object.keys(gameState.playerEntries).length || 0} players
                    </div>
                    <Divider className="mt-2 mb-4" style={{borderColor: '#CACFD5'}}/>
                    <div className="text-sm md:text-base max-h-40 md:max-h-56 overflow-y-auto pr-2 custom-scrollbar">
                        {Object.entries((gameState.playerEntries as Record<string, PlayerEntry>) || {})
                            .sort(([a], [b]) => {
                                if (a === user?.id) return -1;
                                if (b === user?.id) return 1;
                                return a.localeCompare(b);
                            })
                            .map(([playerId, playerEntry]) => (
                                <div
                                    key={playerId}
                                    className={`mb-2 flex flex-wrap items-center ${
                                        playerId === user?.id ? 'bg-indigo-800 rounded-lg p-2' : ''
                                    }`}
                                >
                                    <div
                                        className={`w-full md:w-40 flex-shrink-0 text-base md:text-lg font-semibold mb-2 md:mb-0 md:mr-4 ${
                                            playerId === user?.id ? 'text-green-300' : 'text-gray-300'
                                        }`}
                                    >
                                        {playerNames[playerId] || playerId}
                                    </div>
                                    <div
                                        className="w-full md:flex-grow flex flex-wrap md:flex-nowrap items-center bg-gray-800 rounded-full p-2 pl-4">
                                        {Object.keys(Utils.allItems).map((item) => (
                                            <div key={item} className="flex items-center mr-4 mb-2 md:mb-0">
                        <span
                            className={`text-sm md:text-base mr-2 ${
                                playerId === user?.id ? 'text-green-400' : 'text-gray-400'
                            }`}
                        >
                          {Utils.getSymbol(item, lobbyState.symbols)}
                        </span>
                                                <span className="text-lg md:text-xl font-medium text-white">
                          x {playerEntry[item] || 0}
                        </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GameInterface;
